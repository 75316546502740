import * as React from "react";
import { graphql } from "gatsby";

import Layout from "../components/layout";
import Seo from "../components/seo";

const NotFoundPage = ({ data, location }) => {
  const siteTitle = data.site.siteMetadata.title;

  return (
    <Layout location={location} title={siteTitle}>
      <Seo title="Impressum" />
      <h1>Impressum</h1>
      <>
        <p>
          <strong>palmomedia</strong>
          <br />
          <em>A DevOps Company</em>
          <br />
          09669 - Frankenberg/Sa.
          <br />
          <a href="mailto:hilfe@palmomedia.de">hilfe@palmomedia.de</a>
          <br />
          <br />
          <strong>Vertreten durch:</strong>{" "}
          <a href="https://www.michaelpalmer.de" target="_blank">
            Michael Palmer
          </a>
        </p>
        <p>
          Einzelunternehmen mit der Steuernummer 222 / 255 / 02653
          <br />
          Finanzamt Mittweida
        </p>
        <p>
          <strong>Bildnachweis:</strong> Das Icon/Logo kommt von:{" "}
          <a
            rel="noreferrer noopener"
            aria-label=" (opens in a new tab)"
            href="https://www.flaticon.com/free-icon/laptop_2490631"
            target="_blank"
          >
            www.flaticon.com
          </a>{" "}
          Urheber:{" "}
          <a
            rel="noreferrer noopener"
            aria-label=" (opens in a new tab)"
            href="https://www.flaticon.com/authors/smashicons"
            target="_blank"
          >
            smashicons
          </a>{" "}
          <br />
        </p>

        <h2>Disclaimer - rechtliche Hinweise</h2>
        <p>
          <strong>Auskunfts- und Widerrufsrecht</strong>
        </p>
        <p>
          Sie haben jederzeit das Recht, sich unentgeltlich und unverzüglich
          über die zu Ihrer Person erhobenen Daten zu erkundigen. Ebenfalls
          können Sie Ihre Zustimmung zur Verwendung Ihrer angegebenen
          persönlichen Daten mit Wirkung für die Zukunft widerrufen. Hierfür
          wenden Sie sich bitte an den im Impressum angegebenen Diensteanbieter.
        </p>
        <p>
          Bitte beachten Sie unsere&nbsp;
          <a href="/datenschutzerklaerung">Datenschutzerklärung</a>.
        </p>
        <p>
          Die Nutzung von im Rahmen der Impressumspflicht veröffentlichten
          Kontaktdaten durch Dritte zur Übersendung von nicht ausdrücklich
          angeforderter Werbung und Informationsmaterialien wird hiermit
          ausdrücklich untersagt. Ausgenommen hiervon sind bestehende
          Geschäftsbeziehungen bzw. es liegt Ihnen eine entsprechende
          Einwilligung von uns vor.
        </p>
      </>
    </Layout>
  );
};

export default NotFoundPage;

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
  }
`;
